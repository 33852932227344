<template>
	<ion-page>
		<ion-header :translucent="false">
			<ion-toolbar>
				<ion-buttons slot="start">
					<ion-back-button text="Zurück"></ion-back-button>
				</ion-buttons>
				<ion-title @click="goToRootPage()">WEIN & CO</ion-title>
				<ion-buttons slot="end">
					<ion-button @click="goToRootPage()">Meine Weine</ion-button>
				</ion-buttons>
			</ion-toolbar>
		</ion-header>

		<ion-content>
			<div v-if="loading" class="ion-text-center">
				<br /><br /><br />
				<ion-spinner></ion-spinner>
			</div>
			<div v-if="event && !loading">
				<ion-img v-if="event.imageUrl" :src="event.imageUrl" :alt="event.title" @click="gotoSwitch(event)"></ion-img>
				<h3 v-else class="ion-text-center ion-padding-horizontal ion-padding-top ion-margin-horizontal" size="large">{{ event.title }}</h3>
				<div class="ion-padding-horizontal ion-padding-top">
					<ion-segment v-model="selectedMode">
						<ion-segment-button value="producers">
							<ion-label>Produzenten</ion-label>
						</ion-segment-button>
						<ion-segment-button value="products">
							<ion-label>Produkte</ion-label>
						</ion-segment-button>
					</ion-segment>
				</div>
				<ion-searchbar v-if="selectedMode === 'producers'" :debounce="650" v-model="searchTextProducers" @ionInput="onProducersSearchChange()" class="ion-margin-top" placeholder="Produzent, Land, Region, Stand"></ion-searchbar>
				<ion-searchbar v-if="selectedMode === 'products'" :debounce="650" v-model="searchTextProducts" @ionInput="onProductsSearchChange()" class="ion-margin-top" placeholder="Produkt, Land, Region, Stand"></ion-searchbar>

				<!-- Wine type icons for filtering -->
				<div class="wine-icons">
					<div class="wine-icon-container" @click="toggleWineType('Rotwein')">
						<div class="wine-icon" :class="{ active: isWineTypeActive('Rotwein') }">
							<img src="img/icons/rotwein.svg" alt="Rotwein" />
						</div>
						<span class="wine-label">Rotwein</span>
					</div>
					<div class="wine-icon-container" @click="toggleWineType('Weißwein')">
						<div class="wine-icon" :class="{ active: isWineTypeActive('Weißwein') }">
							<img src="img/icons/weisswein.svg" alt="Weißwein" />
						</div>
						<span class="wine-label">Weißwein</span>
					</div>
					<div class="wine-icon-container" @click="toggleWineType('Roséwein')">
						<div class="wine-icon" :class="{ active: isWineTypeActive('Roséwein') }">
							<img src="img/icons/rosewein.svg" alt="Roséwein" />
						</div>
						<span class="wine-label">Roséwein</span>
					</div>
					<div class="wine-icon-container" @click="toggleWineType('Schaumwein')">
						<div class="wine-icon" :class="{ active: isWineTypeActive('Schaumwein') }">
							<img src="img/icons/schaumwein.svg" alt="Schaumwein" />
						</div>
						<span class="wine-label">Schaumwein</span>
					</div>
					<div class="wine-icon-container" @click="toggleWineType('Alkoholfrei und Alkoholreduziert')">
						<div class="wine-icon" :class="{ active: isWineTypeActive('Alkoholfrei und Alkoholreduziert') }">
							<img src="img/icons/less.svg" alt="Alkoholfrei und Alkoholreduziert" />
						</div>
						<span class="wine-label">Alkoholfrei<br> und -reduziert</span>
					</div>
				</div>

				<ion-list v-if="selectedMode === 'producers'" class="ion-margin-top">
					<template v-if="producerStore?.searchResult?.length > 0">
						<template v-for="(producer) in producerStore?.searchResult" :key="producer.id">
							<ion-item detail="true" @click="goToProducerPage(producer)">
								<ion-label>
									{{ producer.name }} <span v-if="producer.boothInfo"> | <strong>{{ producer.boothInfo }}</strong><span v-if="producer.booth2Info"> & <strong>{{ producer.booth2Info }}</strong></span></span>
								</ion-label>
							</ion-item>
						</template>
					</template>
					<template v-else>
						<div class="ion-text-center ion-padding" v-if="searching">
							<ion-spinner></ion-spinner>
						</div>
						<div v-else class="ion-text-center ion-padding">
							<span v-if="searchTextProducers?.length > 0">
								Kein passender Produzent gefunden.
							</span>
							<span v-else>
								Bitte geben Sie einen Suchbegriff ein.
							</span>
						</div>
					</template>
				</ion-list>
				<ion-list v-if="selectedMode === 'products'" class="ion-margin-top">
					<template v-if="productStore?.searchResult?.length > 0">
						<template v-for="(product) in productStore?.searchResult" :key="product.id">
							<ion-item detail="true" @click="goToProductPage(product)">
								<ion-label class="product-label">
									<div class="label-content">
										<span class="product-name">{{ product.name }}</span>
										<span v-html="product.price" class="price-sm" v-if="product.price"></span>
									</div>
									<small>
										<span v-if="product.producerBoothInfo">
											<strong>{{ product.producerBoothInfo }}</strong>
											<span v-if="product.producerBooth2Info"> & <strong>{{ product.producerBooth2Info }}</strong></span> |
										</span>
										<i>{{ product.producerName }}</i>
									</small>
								</ion-label>
							</ion-item>
						</template>
					</template>
					<template v-else>
						<div class="ion-text-center ion-padding" v-if="searching">
							<ion-spinner></ion-spinner>
						</div>
						<div v-else class="ion-text-center ion-padding">
							<span v-if="searchTextProducts?.length > 0">
								Kein passendes Produkt gefunden.
							</span>
							<span v-else>
								Bitte geben Sie einen Suchbegriff ein.
							</span>
						</div>
					</template>
				</ion-list>
				<div v-if="event.footerImageUrl" style="margin-top:40px;margin-bottom:-30px;">
					<ion-img class="ion-margin-top" :src="event.footerImageUrl" :alt="event.name"></ion-img>
				</div>
			</div>
			<footer-content v-if="event && !loading"></footer-content>
		</ion-content>
	</ion-page>
</template>

<script>
import { IonSearchbar, IonImg, IonContent, IonButtons, IonButton, IonHeader, IonSpinner, IonPage, IonTitle, IonToolbar, IonBackButton, IonList, IonItem, IonLabel, IonSegment, IonSegmentButton } from '@ionic/vue';
import { defineComponent, onMounted, ref, watch, watchEffect } from 'vue';
import { useIdentityStore } from '../stores/IdentityStore';
import { useEventStore } from '../stores/EventStore';
import { useProducerStore } from '../stores/ProducerStore';
import { useProductStore } from '../stores/ProductStore';
import { useRouter } from 'vue-router';
import FooterContent from '../components/FooterContent.vue';

export default defineComponent({
	name: 'EventSearchPage',
	props: ['id'],
	components: {
		IonSearchbar,
		IonImg,
		IonButtons,
		IonButton,
		IonContent,
		IonHeader,
		IonPage,
		IonTitle,
		IonBackButton,
		IonToolbar,
		IonList,
		IonItem,
		IonLabel,
		IonSpinner,
		IonSegment,
		IonSegmentButton,
		FooterContent
	},
	setup(props) {
		const searchTextProducers = ref('');
		const searchTextProducts = ref('');
		const selectedWineType = ref(null);
		const eventStore = useEventStore();
		const productStore = useProductStore();
		const producerStore = useProducerStore();
		const identityStore = useIdentityStore();
		const loading = ref(false);
		const event = ref(null);
		const selectedMode = ref('producers');
		const router = useRouter();
		const searching = ref(false);

		watchEffect(() => {
			const queryMode = router.currentRoute.value.query.mode;
			if (queryMode) {
				selectedMode.value = queryMode;
			}
		});

		watch(selectedMode, (newMode) => {
			// Reset wine type and update search fields when switching modes
			resetWineType();
			router.push({ query: { mode: newMode } });
		});

		const resetWineType = () => {
			selectedWineType.value = null;
			updateSearchText();
		};

		const updateSearchText = () => {
			const wineTypes = ['Rotwein', 'Weißwein', 'Roséwein', 'Schaumwein', 'Alkoholfrei und Alkoholreduziert'];
			const wineTypeLabel = selectedWineType.value || '';
			const baseText = selectedMode.value === 'producers' ? searchTextProducers.value : searchTextProducts.value;

			let filteredText = baseText;
			wineTypes.forEach(type => {
				const regex = new RegExp(type, 'gi');
				filteredText = filteredText.replace(regex, '').trim();
			});

			const updatedText = wineTypeLabel ? `${filteredText} ${wineTypeLabel}`.trim() : filteredText;

			if (selectedMode.value === 'producers') {
				searchTextProducers.value = updatedText;
				onProducersSearchChange();
			} else if (selectedMode.value === 'products') {
				searchTextProducts.value = updatedText;
				onProductsSearchChange();
			}
		};


		const toggleWineType = (type) => {
			selectedWineType.value = selectedWineType.value === type ? null : type;
			updateSearchText();
		};

		const onProducersSearchChange = async () => {
			if (searchTextProducers.value.trim().length === 0) {
				selectedWineType.value = null;
				producerStore.searchResult = [];
				return;
			}
			if (searchTextProducers.value.length >= 2) {
				searching.value = true;
				await producerStore.searchProducers(event.value.token, searchTextProducers.value.toLowerCase(), identityStore.identity);
				searching.value = false;
			}
		};

		const onProductsSearchChange = async () => {
			if (searchTextProducts.value.trim().length === 0) {
				selectedWineType.value = null;
				productStore.searchResult = [];
				return;
			}
			if (searchTextProducts.value.length >= 2) {
				searching.value = true;
				await productStore.searchProducts(event.value.token, searchTextProducts.value.toLowerCase(), identityStore.identity);
				searching.value = false;
			}
		};


		onMounted(async () => {
			loading.value = true;
			await eventStore.getEvent(props.id, identityStore.identity);
			event.value = eventStore.event;
			loading.value = false;
		});

		const goToRootPage = () => {
			router.push({ name: 'Root', params: {} });
		};

		const goToProducerPage = (producer) => {
			router.push({ name: 'Producer', params: { id: producer.token } });
		};

		const goToProductPage = (product) => {
			router.push({ name: 'Product', params: { id: product.token } });
		};

		const gotoSwitch = (event) => {
			if (event.numberOfBooths > 0) {
				router.push({ name: 'EventBooths', params: { id: event.token } });
			} else {
				router.push({ name: 'Root', params: {} });
			}
		};

		const isWineTypeActive = (type) => selectedWineType.value === type;

		return {
			loading,
			event,
			selectedMode,
			gotoSwitch,
			goToRootPage,
			goToProducerPage,
			goToProductPage,
			searchTextProducers,
			searchTextProducts,
			onProducersSearchChange,
			onProductsSearchChange,
			producerStore,
			productStore,
			searching,
			selectedWineType,
			toggleWineType,
			isWineTypeActive
		};
	}
});
</script>

<style scoped>
ion-spinner {
	--color: #bc0936;
}

.wine-icons {
	display: flex;
	justify-content: space-around;
	margin-top: 10px;
}

.wine-icon-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	cursor: pointer;
}

.wine-icon {
	width: 70px;
	height: 70px;
	border-radius: 50%;
	border: 2px solid #efefef;
	overflow: hidden;
}

.wine-icon.active {
	border-color: var(--ion-color-primary);
}

.wine-icon img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.wine-label {
	margin-top: 5px;
	font-size: 11px;
	text-align: center;
}
</style>
