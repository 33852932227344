<template>
	<ion-header>
		<ion-toolbar>
			<ion-buttons slot="start">
				<ion-button color="medium" @click="cancel">Abbrechen</ion-button>
			</ion-buttons>
			<ion-title></ion-title>
			<ion-buttons slot="end">
				<ion-button @click="confirm">Speichern</ion-button>
			</ion-buttons>
		</ion-toolbar>
	</ion-header>
	<ion-content class="ion-padding">
		<h3 class="ion-text-center ion-padding-horizontal ion-margin-horizontal" size="large">Meine Bewertung</h3>
		<star-rating class="ion-justify-content-center ion-margin-top" active-color="#bc0936" :star-size="40" :show-rating="false" v-model:rating="rating"></star-rating>
		<br/>
		<ion-list>
			<ion-item>
				<ion-textarea autoGrow v-model="note" placeholder="Meine Notiz..."></ion-textarea>
			</ion-item>
		</ion-list>
	</ion-content>
</template>

<script>
import { IonContent, IonHeader, IonTitle, IonToolbar, IonButtons, IonButton, IonList, IonItem, IonTextarea, modalController } from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import StarRating from 'vue-star-rating';

export default defineComponent({
	name: 'RatingModal',
	components: { IonContent, IonHeader, IonTitle, IonToolbar, IonButtons, IonButton, IonList, IonItem, IonTextarea, StarRating },
	props: ['existingRating', 'existingNote'],
	setup(props) {
		const note = ref(props.existingNote);
		const rating = ref(props.existingRating);

		function cancel() {
			modalController.dismiss(null, 'cancel');
		}

		function confirm() {
			modalController.dismiss({ rating: rating.value, note: note.value }, 'confirm');
		}

		return {
			note,
			rating,
			cancel,
			confirm,
		};
	},
});
</script>
