<template>
    <ion-header>
        <ion-toolbar>
            <ion-buttons slot="start">
                <ion-button color="medium" @click="cancel">Abbrechen</ion-button>
            </ion-buttons>
            <ion-title></ion-title>
            <ion-buttons slot="end">
                <ion-button :disabled="!isFormValid" @click="send">Senden</ion-button>
            </ion-buttons>
        </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
        <h3 class="ion-text-center ion-padding-horizontal ion-margin-horizontal" size="large">Meine Weine<br /> als E-Mail senden</h3>
        <ion-list class="ion-margin-top">
            <ion-item>
                <ion-input type="email" required placeholder="Meine E-Mail Adresse" v-model="email"></ion-input>
            </ion-item>
        </ion-list>

        <ion-list lines="none" class="ion-margin-top">
            <ion-item>
                <ion-checkbox alignment="center" v-model="accepted" required>
                </ion-checkbox>
                <div class="ion-text-wrap" style="padding-left:20px; font-size:11.5px;">
                    Ich akzeptiere die <a target="_blank" href="https://www.weinco.at/footer/information/datenschutz">Datenschutzbedingungen</a> und bestätige, dass meine freiwillig zur Verfügung gestellten personenbezogenen Daten (E-Mail-Adresse) von der WEIN & CO Handelsgesellschaft.m.b.H., Hans Dujsik-Allee 7 / Top 25, 2334 Vösendorf-Süd ("WEIN & CO") zu Marketingzwecken per E-Mail verarbeitet werden. Ich weiß, dass Ich diese Einwilligung jederzeit mit Wirkung für die Zukunft durch Kontaktaufnahme mit WEIN & CO widerrufen kann.</div>
            </ion-item>
            <br>
            <ion-item>
                <ion-checkbox alignment="center" v-model="acceptedNewsletter"></ion-checkbox>
                <div class="ion-text-wrap" style="padding-left:20px; font-size:11.5px;">
                    Zum <strong>WEIN & CO Newsletter</strong> anmelden. Ich willige ein, dass meine angegebenen personenbezogenen Daten von WEIN & CO für Zusendungen zu Werbezwecken, wie Aktionen, News und Event-Einladungen per E-Mail verarbeitet werden.
                    Ich weiß, dass ich diese Einwilligung jederzeit mit Wirkung für die Zukunft für alle oder einzelne Zwecke durch Kontaktaufnahme mit WEIN & CO (z. B. über einen Link am Ende jeden Newsletters oder über unseren Abmeldelink auf der Website) widerrufen kann. Weitere Details finde ich in den <a target="_blank" href="https://www.weinco.at/footer/information/datenschutz">Datenschutzhinweisen</a>.
                </div>
            </ion-item>
        </ion-list>
    </ion-content>
</template>

<script>
import { IonContent, IonCheckbox, IonHeader, IonTitle, IonToolbar, IonButtons, IonButton, IonList, IonItem, IonInput, modalController } from '@ionic/vue';
import { defineComponent, ref, computed } from 'vue';
import { useIdentityStore } from '../stores/IdentityStore';
import { useEventStore } from '../stores/EventStore';

export default defineComponent({
    name: 'EmailModal',
    components: { IonContent, IonCheckbox, IonHeader, IonTitle, IonToolbar, IonButtons, IonButton, IonList, IonItem, IonInput },
    props: ['event'],
    setup(props) {
        const email = ref('');
        const accepted = ref(false);
        const acceptedNewsletter = ref(false); // Neue Checkbox für Newsletter
        const identityStore = useIdentityStore();
        const eventStore = useEventStore();

        function cancel() {
            modalController.dismiss(null, 'cancel');
        }

        const isFormValid = computed(() => {
            return accepted.value && email.value.trim() !== '' && isEmailValid(email.value);
        });

        function isEmailValid(email) {
            return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
        }

        async function send() {
            if (accepted.value && email.value) {
                await eventStore.sendListPerEmail(props.event, email.value, identityStore.identity, acceptedNewsletter.value);
            }
            modalController.dismiss(null, 'confirm');
        }

        return {
            email,
            accepted,
            acceptedNewsletter,
            cancel,
            send,
            isFormValid
        };
    },
});
</script>

<style>
ion-checkbox {
    --size: 32px;
    width: 40px;
    --checkbox-background-checked: #bc0936;
}

ion-checkbox::part(container) {
    border: 1px solid #bc0936;
}
</style>
