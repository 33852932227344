<template>
	<ion-page>
		<ion-header :translucent="false">
			<ion-toolbar>
				<ion-buttons slot="start">
					<ion-back-button text="Zurück"></ion-back-button>
				</ion-buttons>
				<ion-title @click="goToRootPage()">WEIN & CO</ion-title>
				<ion-buttons slot="end">
					<ion-button @click="goToRootPage()">Meine Weine</ion-button>
				</ion-buttons>
			</ion-toolbar>
		</ion-header>

		<ion-content ref="fullContent">
			<div v-if="loading" class="ion-text-center">
				<br /><br /><br />
				<ion-spinner></ion-spinner>
			</div>

			<div v-if="event && !loading">
				<div v-if="event.id != 6">
					<img v-if="event.locationImageUrl" :src="event.locationImageUrl" :alt="event.title" />
					<h3 v-else class="ion-text-center ion-padding-horizontal ion-padding-top ion-margin-horizontal" size="large">{{ event.title }}</h3>
				</div>
				<div v-else>
					<map-content @changeSearchText="handleSearchTextChange"></map-content>
				</div>

				<ion-searchbar class="ion-margin-top" v-model="searchText" placeholder="Suche Produzent, Land, Region"></ion-searchbar>

				<!-- Wine type icons for filtering -->
				<div class="wine-icons">
					<div class="wine-icon-container" @click="toggleWineType('red_wine')">
						<div class="wine-icon" :class="{ active: isWineTypeActive('red_wine') }">
							<img src="img/icons/rotwein.svg" alt="Rotwein" />
						</div>
						<span class="wine-label">Rotwein</span>
					</div>
					<div class="wine-icon-container" @click="toggleWineType('white_wine')">
						<div class="wine-icon" :class="{ active: isWineTypeActive('white_wine') }">
							<img src="img/icons/weisswein.svg" alt="Weißwein" />
						</div>
						<span class="wine-label">Weißwein</span>
					</div>
					<div class="wine-icon-container" @click="toggleWineType('rose_wine')">
						<div class="wine-icon" :class="{ active: isWineTypeActive('rose_wine') }">
							<img src="img/icons/rosewein.svg" alt="Roséwein" />
						</div>
						<span class="wine-label">Roséwein</span>
					</div>
					<div class="wine-icon-container" @click="toggleWineType('sparkling_wine')">
						<div class="wine-icon" :class="{ active: isWineTypeActive('sparkling_wine') }">
							<img src="img/icons/schaumwein.svg" alt="Schaumwein" />
						</div>
						<span class="wine-label">Schaumwein</span>
					</div>
					<div class="wine-icon-container" @click="toggleWineType('alc_free_reduced')">
						<div class="wine-icon" :class="{ active: isWineTypeActive('alc_free_reduced') }">
							<img src="img/icons/less.svg" alt="Alkoholfrei und Alkoholreduziert" />
						</div>
						<span class="wine-label">Alkoholfrei<br> und -reduziert</span>
					</div>
				</div>

				<template v-if="noProducersFound">
					<br>
					<div class="ion-text-center ion-padding">Keine Produzenten gefunden.</div>
				</template>

				<template v-else>
					<template v-for="(booth) in nonEmptyBooths" :key="booth.id">
						<h3 class="ion-padding-horizontal" style="margin-bottom:0px;" size="large">{{ booth.name }}</h3>
						<div v-if="booth.description" class="ion-padding-horizontal ion-padding-top">{{ booth.description }}</div>
						<ion-list style="margin-bottom: 32px;">
							<template v-for="(producer) in booth.producers" :key="producer.id">
								<ion-item detail="true" @click="goToProducerPage(producer)">
									<ion-label>{{ producer.name }}</ion-label>
								</ion-item>
							</template>
						</ion-list>
					</template>
				</template>

				<div v-if="activeBoothSearch" class="ion-text-center ion-margin-bottom">
					<ion-button @click="resetSearch()">Alle Stände anzeigen</ion-button>
				</div>

				<div v-if="event.footerImageUrl" style="margin-top:40px;margin-bottom:-30px;">
					<ion-img class="ion-margin-top" :src="event.footerImageUrl" :alt="event.name"></ion-img>
				</div>
			</div>
			<footer-content v-if="event && !loading"></footer-content>
		</ion-content>
	</ion-page>
</template>

<script>
import { IonSearchbar, IonImg, IonContent, IonButton, IonButtons, IonHeader, IonSpinner, IonPage, IonTitle, IonToolbar, IonBackButton, IonList, IonItem, IonLabel } from '@ionic/vue';
import { defineComponent, onMounted, ref, computed, watchEffect } from 'vue';
import { useIdentityStore } from '../stores/IdentityStore';
import { useEventBoothStore } from '../stores/EventBoothStore';
import { useRouter } from 'vue-router';
import FooterContent from '../components/FooterContent.vue';
import MapContent from '../components/MapContent.vue';

export default defineComponent({
	name: 'EventBoothsPage',
	props: ['id'],
	components: {
		IonSearchbar,
		IonButtons,
		IonContent,
		IonHeader,
		IonButton,
		IonPage,
		IonTitle,
		IonBackButton,
		IonToolbar,
		IonList,
		IonItem,
		IonImg,
		IonLabel,
		IonSpinner,
		FooterContent,
		MapContent
	},
	setup(props) {
		const eventBoothStore = useEventBoothStore();
		const identityStore = useIdentityStore();
		const loading = ref(false);
		const event = ref(null);
		const router = useRouter();
		const searchText = ref('');
		const selectedWineType = ref(null);
		const activeBoothSearch = ref(false);

		watchEffect(() => {
			const queryBooth = router.currentRoute.value.query.f;
			if (queryBooth) {
				searchText.value = queryBooth;
			}
		});

		onMounted(async () => {
			loading.value = true;
			await eventBoothStore.getEventBooths(props.id, identityStore.identity);
			event.value = eventBoothStore.event;
			loading.value = false;
		});

		const toggleWineType = (type) => {
			// Toggle selected wine type
			selectedWineType.value = selectedWineType.value === type ? null : type;
		};

		const nonEmptyBooths = computed(() => {
			let searchKeywords = searchText.value.toLowerCase().trim().split(' ');
			const boothTokensToFilter = searchKeywords
				.filter(keyword => keyword.startsWith('stand-'))
				.map(keyword => keyword.substring(6).toLowerCase());

			searchKeywords = searchKeywords.filter(keyword => !keyword.startsWith('stand-'));

			return event.value.booths
				.filter(booth => booth.producers.length > 0)
				.filter(booth => {
					if (boothTokensToFilter.length === 0) {
						return true;
					} else {
						return boothTokensToFilter.includes(booth.token);
					}
				})
				.map(booth => {
					const matchingProducers = booth.producers.filter(producer =>
						// Apply wine type filter and search keywords
						(!selectedWineType.value || producer[selectedWineType.value]) &&
						searchKeywords.every(keyword => {
							keyword = keyword.toLowerCase();
							return (
								(producer.name && producer.name.toLowerCase().includes(keyword)) ||
								(producer.country && producer.country.toLowerCase().includes(keyword)) ||
								(producer.region && producer.region.toLowerCase().includes(keyword)) ||
								(producer.boothInfo && producer.boothInfo.toLowerCase() === keyword) ||
								(producer.booth2Info && producer.booth2Info.toLowerCase() === keyword)
							);
						})
					);
					return matchingProducers.length > 0 ? { ...booth, producers: matchingProducers } : null;
				})
				.filter(booth => booth !== null);
		});

		const noProducersFound = computed(() => {
			return nonEmptyBooths.value.every(booth => booth.producers.length === 0);
		});

		const goToRootPage = () => {
			router.push({ name: 'Root', params: {} });
		};

		const resetSearch = () => {
			searchText.value = '';
			selectedWineType.value = null;
		};

		const handleSearchTextChange = (value) => {
			searchText.value = value;
		};

		const goToProducerPage = (producer) => {
			router.push({ name: 'Producer', params: { id: producer.token } });
		};

		const isWineTypeActive = (type) => selectedWineType.value === type;

		return {
			loading,
			event,
			searchText,
			selectedWineType,
			nonEmptyBooths,
			noProducersFound,
			goToRootPage,
			resetSearch,
			handleSearchTextChange,
			goToProducerPage,
			isWineTypeActive,
			toggleWineType,
			activeBoothSearch
		};
	}
});
</script>

<style scoped>
ion-spinner {
	--color: #bc0936;
}

.wine-icons {
	display: flex;
	justify-content: space-around;
	margin-top: 10px;
}

.wine-icon-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	cursor: pointer;
}

.wine-icon {
	width: 70px;
	height: 70px;
	border-radius: 50%;
	border: 2px solid #efefef;
	overflow: hidden;
}

.wine-icon.active {
	border-color: var(--ion-color-primary);
}

.wine-icon img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.wine-label {
	margin-top: 5px;
	font-size: 11px;
	text-align: center;
}
</style>
